import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

import { Grid, GridSize, Typography } from "@material-ui/core";
import moment from "moment";

import { formatUserInfo } from "../helpers/telegramHelpers";

interface UrlParams {
  userId: string;
}

interface MessagesParams {
  message: any;
  xs: GridSize;
}

interface UserMessagesParams {
  messageFetchFunction: any;
  userFetchFunction: any;
}

function UserMessages({
  messageFetchFunction,
  userFetchFunction,
}: UserMessagesParams) {
  const [messages, setMessages] = useState<Array<any> | undefined>();
  const { userId } = useParams<UrlParams>();
  useEffect(() => {
    // fetch messages effect
    const fetchMessages = async () => {
      try {
        const fetchedMessages = await messageFetchFunction(parseInt(userId));
        setMessages(fetchedMessages);
      } catch (error) {
        console.error(error);
      }
    };

    fetchMessages();
  }, [userId, messageFetchFunction]);

  const [user, setUser] = useState<null | Record<string, any>>(null);
  useEffect(() => {
    // fetch user information effect
    const fetchUser = async () => {
      try {
        const user = await userFetchFunction(parseInt(userId));
        setUser(user);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUser();
  }, [userId, userFetchFunction]);

  const Message = ({ message, xs }: MessagesParams) => {    
    return (
      <Grid item xs={xs}>
        <Card elevation={3}>
          <CardContent>
            <Typography color="textSecondary">
              {moment(message.created.seconds * 1000).format("MMM Do YY")} (
              {moment(message.created.seconds * 1000).fromNow()})
            </Typography>
            <Typography color="textSecondary">
              Sender: {formatUserInfo(message.from_user)}
            </Typography>
            <Typography color="textSecondary">
              Receiver: {formatUserInfo(message.to_user)}
            </Typography>
            <Typography dir="auto">{message.message_text}</Typography>
            {message.confirmed_by_the_sender ? (
              <CheckBoxIcon style={{ marginRight: "1em" }} />
            ) : (
              <CheckBoxOutlineBlankIcon style={{ marginRight: "1em" }} />
            )}
            {message.seen_by_the_receiver ? (
              <VisibilityIcon />
            ) : (
              <VisibilityOffIcon />
            )}
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{ marginTop: "1em" }}>
        <Typography component="h1">{user && formatUserInfo(user)}{`(${messages?.length || 0})`}</Typography>
      </Grid>
      {messages?.filter((m) => m.message_text)
          .map((message) => (
            <Message key={message.id} xs={12} message={message} />
          ))}      
    </Grid>
  );
}

export default UserMessages;
