import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import firebase from "firebase/app";
import { Container } from "@material-ui/core";

import BotAppBar from '../bot-app-bar-component/BotAppBar';
import Login from "../login-page/Login";
import UserList from "../user-list-page/UserList";
import UserMessages from "../user-messages-page/UserMessages";
import UserUpdates from "../user-updates-page/UserUpdates";

interface AuthenticatedParams {
  component: JSX.Element;
  firebaseUser: firebase.User | null;
  setTitle: () => void
}

const Authenticated = ({ component, firebaseUser, setTitle }: AuthenticatedParams) => {
  useEffect(() => {
    if (firebaseUser) {
      setTitle();
    }
  });
  if (firebaseUser) {
    return component;
  } else {
    return <Login setTitle={setTitle} />;
  }
};

const App = () => {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [title, setAppTitle] = useState<string>('User List');

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  }, []);

  const userFetchFunction = async (userId: number) => {
    const userSnapshot = await firebase
      .firestore()
      .collection("user_chat_ids")
      .where('user_id', '==', userId)
      .get();
    return await userSnapshot.docs[0].data();
  };

  return (
    <Container>
      <BotAppBar title={title} />
      <Router>
        <Switch>
          <Route path="/login" render={() => {
            if (!user) {
              return <Login setTitle={setAppTitle} />
            }
            return <Redirect to="/" />
          }} />
          <Route path="/user-updates/:userId">
            <Authenticated firebaseUser={user} component={<UserUpdates updateFetchFunction={async (userId: number) => {
              const updatesSnapshot = await firebase
                .firestore()
                .collection('updates')
                .where('message.from.id', '==', userId)
                .orderBy('message.date', 'desc')
                .get();
              const fetchedUpdates: Array<any> = [];
              updatesSnapshot.docs.forEach(async (doc) => {
                fetchedUpdates.push({
                  id: doc.id,
                  ...doc.data()
                });

              });
              return fetchedUpdates;
            }} />} setTitle={() => { setAppTitle("Updates") }} />
          </Route>
          <Route path="/user-inbox/:userId">
            <Authenticated firebaseUser={user} component={<UserMessages userFetchFunction={userFetchFunction} messageFetchFunction={async (userId: number) => {
              const messagesSnapshot = await firebase
                .firestore()
                .collection("messages")
                .where('to_user_id', '==', userId)
                .orderBy('created', 'desc')
                .get();
              const fetchedMessages: Array<any> = [];
              messagesSnapshot.docs.forEach(async (doc) => {
                fetchedMessages.push({
                  id: doc.id,
                  ...doc.data()
                });
              });
              return fetchedMessages;
            }} />} setTitle={() => { setAppTitle("Inbox") }} />
          </Route>
          <Route path="/user-outbox/:userId">
            <Authenticated firebaseUser={user} component={<UserMessages userFetchFunction={userFetchFunction} messageFetchFunction={async (userId: number) => {
              const messagesSnapshot = await firebase
                .firestore()
                .collection("messages")
                .where('from_user_id', '==', userId)
                .orderBy('created', 'desc')
                .get();
              const fetchedMessages: Array<any> = [];
              messagesSnapshot.docs.forEach(async (doc) => {
                fetchedMessages.push({
                  id: doc.id,
                  ...doc.data()
                });
              });
              return fetchedMessages;
            }} />} setTitle={() => { setAppTitle("Outbox") }} />
          </Route>
          <Route path="/">
            <Authenticated firebaseUser={user} component={<UserList />} setTitle={() => { setAppTitle("User List") }} />
          </Route>
        </Switch>
      </Router>
    </Container>
  );
};

export default App;
