import React from "react";

import { Button, Paper, Typography } from "@material-ui/core";
import moment from "moment";

interface UserUpdateParams {
    update: Record<string, any>;
}

function UserUpdate ({ update }: UserUpdateParams) {
    return <Paper style={{margin: '1em 0', padding: '1em'}}>
        <Typography color="textSecondary">{moment(update.message?.date * 1000).fromNow()}</Typography>        
        <Typography dir="auto">{update.message?.text}</Typography>
        {update.message.photo && <Button color="primary" variant="contained" href={`/getMedia?file_id=${update.message.photo[2].file_id}`}>Fetch image</Button>}
    </Paper>;
}

export default UserUpdate;