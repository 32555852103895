import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";

import UserUpdate from "./UserUpdate";

interface UserUpdatesParams {
  updateFetchFunction: any;
}

interface UrlParams {
  userId: string;
}

function UserUpdates({ updateFetchFunction }: UserUpdatesParams) {
  const [updates, setUpdates] = useState<Array<any> | undefined>();
  const { userId } = useParams<UrlParams>();
  useEffect(() => {
    const fetchUpdates = async () => {
      try {
        const fetchedUpdates = await updateFetchFunction(parseInt(userId));

        setUpdates(fetchedUpdates);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUpdates();
  }, [userId, updateFetchFunction]);
  return (
    <Grid container>
      <Grid item xs>
        {updates?.map((update) => {
          return <UserUpdate key={update.id} update={update} />;
        })}
      </Grid>
    </Grid>
  );
}

export default UserUpdates;
