import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import debounce from "lodash.debounce";

import firebase from "firebase";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import SearchIcon from "@material-ui/icons/Search";

import {
  Avatar,
  Button,
  Grid,
  InputBase,
  makeStyles,
  Typography,
  fade,
} from "@material-ui/core";

import { formatUserInfo } from "../helpers/telegramHelpers";

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function UserList() {
  const [users, setUsers] = useState<Array<any>>([]);
  const [filteredUsers, setFilteredUsers] = useState<Array<any>>([]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const debouncedSearch = debounce(() => {
      setFilteredUsers(
        users.filter(
          (u) =>
            u.first_name?.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
            u.last_name?.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
            u.username?.toLowerCase().indexOf(value.toLowerCase()) > -1
        )
      );
    }, 50);

    debouncedSearch();
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersSnapshot = await firebase
          .firestore()
          .collection("user_chat_ids")
          .get();
        const fetchedUsers: Array<any> = [];
        usersSnapshot.docs.forEach(async (doc) => {
          fetchedUsers.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setUsers(fetchedUsers);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUsers();
  }, []);

  const classes = useStyles();

  const resetUserStatus = async (user: any) => {
    await firebase.firestore().collection("user_chat_ids").doc(user.id).update({
      state: {},
    });
  };

  const formatUserState = (user: any) => {
    if (user.state?.action === "chatting") {
      return `User is currently chatting with ${formatUserInfo(
        users!.find((u) => u.chat_id === user.state.target_chat_id)
      )}`;
    }
    if (user.state?.action === "replying") {
      return `User is currently replying to a message ...`;
    }
    if (user.state?.action === "confirming") {
      return `User is currently confirming a message ...`;
    }

    return "Inactive";
  };

  return (
    <Grid container spacing={3} style={{ marginTop: "1em" }}>
      <Grid item xs={12}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            onChange={handleSearchChange}
          />
        </div>
      </Grid>
      {(filteredUsers.length > 0 ? filteredUsers : users).map((user) => (
        <Grid item xs={12} sm={6} key={user.chat_id}>
          <Card elevation={3}>
            <CardContent>
              <Grid container wrap="nowrap">
                <Grid item>
                  <Avatar></Avatar>
                </Grid>
                <Grid item style={{ marginLeft: "1em" }}>
                  <Typography>{formatUserInfo(user)}</Typography>
                  <Typography color="textSecondary">
                    {formatUserState(user)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              {user.state.action && (
                <Button
                  onClick={async () => await resetUserStatus(user)}
                  color="secondary"
                  size="small"
                >
                  Reset
                </Button>
              )}
              <Button
                component={RouterLink}
                to={`/user-inbox/${user.chat_id}`}
                color="primary"
                size="small"
              >
                Inbox
              </Button>
              <Button
                component={RouterLink}
                to={`/user-outbox/${user.chat_id}`}
                color="primary"
                size="small"
              >
                Outbox
              </Button>
              <Button
                component={RouterLink}
                to={`/user-updates/${user.chat_id}`}
                color="primary"
                size="small"
              >
                Updates
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default UserList;
