import React, { useEffect } from "react";
import firebase from "firebase";

import { Button, TextField, Snackbar, Grid } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

interface LoginParams {
  setTitle: (title: string) => void;
}

function Login({ setTitle }: LoginParams) {
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  useEffect(() => {
    setTitle("Login");
  });

  const handleLoginClick = async (e: React.MouseEvent) => {
    try {
      await firebase.auth().signInWithEmailAndPassword(username, password);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <Grid container direction="column">
      <Snackbar open={errorMessage !== null}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Grid item xs={12}>
        <TextField
          onChange={(e) => setUsername(e.target.value)}
          style={{ marginBottom: '.8em', width: '100%' }}
          id="username"
          label="Username"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          onChange={(e) => setPassword(e.target.value)}
          style={{ marginBottom: '.8em', width: '100%' }}
          id="password"
          type="password"
          label="Password"
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={handleLoginClick}
          style={{ margin: 8 }}
          variant="contained"
          color="primary"
        >
          Login
        </Button>
      </Grid>
    </Grid>
  );
}

export default Login;
